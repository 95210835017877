/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
const { angular } = window;

angular
  .module('wcm.admin')
  .controller('monitoringVisitorCtrl', function(
    $scope,
    $state,
    $q,
    $timeout,
    monitoringFactory,
    breadcrumbs,
    analyticsFactory2
  ) {
    // eslint-disable-next-line no-multi-assign
    const state = ($scope.state = {});
    $scope.handlers = {};
    $scope.chartsOptions = analyticsFactory2.chartsOptions;

    state.id = $state.params.id;
    $scope.setTabs([
      {
        name: 'visitorGeneral',
        sref: 'monitoring.visitors.view.general'
      },
      { name: 'visitorTrends', sref: 'monitoring.visitors.view.trends' },
      {
        name: 'visitorPlaces',
        sref: [
          'monitoring.visitors.view.places.list',
          'monitoring.visitors.view.places.map'
        ]
      },
      {
        name: 'visitorInterests',
        sref: 'monitoring.visitors.view.interests'
      }
    ]);
    breadcrumbs.set({
      path: 'monitoring>visitorsList>visitorDetails'
        .split('>')
        .map(name => ({ name })),
      itemName: state.id
    });

    state.chartsData = {};
    state.blocksState = {};

    $scope.close = function() {
      $state.go(
        $state.params.reportId
          ? 'reporting.monitoring.view'
          : 'monitoring.visitors.list',
        { id: $state.params.reportId }
      );
    };
  });
