/*
 * Copyright (C) Whirl Software PTE LTD. 2014-2017 - All Rights Reserved
 * 600 North Bridge Road, Parkview Square #15-10, Singapore
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * License: see file “LICENSE.txt”
 */
import i18next from 'i18next';
import i18nextConfig from './index';
import { injectNgDeps } from 'ngDeps';
import axios from 'axios';

const { angular } = window;

angular
  .module('wcm.i18n')
  .factory('i18nFactory', function(
    requestFactory,
    $rootScope,
    $q,
    localStorage,
    sessionStorage,
    I18N_CONFIG
  ) {
    const localeImages = require.context('assets/images/lang', false, /\.png$/);

    let globalDfd;
    const state = {
      locales: {},
      preferredLocales: {},
      availableLocales: [],
      defaultLocale: {},
      currentLocale: {}
    };

    let highlightI18n = false;
    let cache = {};
    const slice = Array.prototype.slice;

    /**
     * Initializes i18n subsystem
     * @returns [Promise] - global promise of i18n ready state.
     * Resolves when all preferred and fallback locales
     * are downloaded
     */
    function init() {
      if (globalDfd) {
        return globalDfd.promise;
      }
      globalDfd = $q.defer();

      updateLocalizationState()
        .then(info => {
          return Promise.resolve(
            i18nextConfig.init({
              lowerCaseLng: true,
              lng: info.defaultLocale.id,
              debug: false,
              fallbackLng: 'en-us',
              returnEmptyString: false,
              ns: ['general', 'errorMessages'],
              defaultNS: 'general',
              load: 'currentOnly',
              backend: {
                loadPath: 'admin_locales/{{lng}}/{{ns}}.json'
              },
              react: {
                useSuspense: false
              }
            })
          ).then(() => info);
        })
        .then(function(info) {
          let localesToLoad = window._.uniq([
            I18N_CONFIG.fallbackLocale,
            state.preferredLocales.login,
            state.preferredLocales.system
          ]).filter(window._.identity);
          localesToLoad = window._.intersection(localesToLoad, [
            ...state.availableLocalesIds,
            I18N_CONFIG.fallbackLocale
          ]);
          localesToLoad = localesToLoad.length
            ? localesToLoad
            : [info.defaultLocale.id];

          return $q.all(localesToLoad.map(loadLocale));
        })
        .then(function() {
          globalDfd.resolve(getState());
        })
        .catch(function() {
          globalDfd.reject();
        });
      return globalDfd.promise;
    }

    /**
     * Updates localization state by checking available localizations.
     * If current localization isn't available,
     * sets it to system default
     * @returns {*}
     */
    function updateLocalizationState() {
      return getAvailableLocalizations().then(function(info) {
        state.availableLocales = info.availableLocales;
        state.defaultLocale = info.defaultLocale;
        state.availableLocalesIds = info.availableLocales.map(
          locale => locale.id
        );
        state.preferredLocales = {
          login: sessionStorage.get(I18N_CONFIG.loginLocaleKey),
          system: localStorage.get(I18N_CONFIG.systemLocaleKey)
        };
        if (
          state.currentLocale.id &&
          !~state.availableLocalesIds.indexOf(state.currentLocale.id)
        ) {
          setCurrentLocale();
        }
        return info;
      });
    }

    /**
     * Fetches locale, then adds it to set of downloaded locales
     * @param localeId
     * @returns {*}
     */
    function loadLocale(localeId) {
      return fetchLocale(localeId).then(function(locale) {
        state.locales[localeId] = locale;
        return locale;
      });
    }

    /**
     * Fetches specified locale and performs required transforms
     * @param localeId
     * @returns {*}
     */
    function fetchLocale(localeId) {
      return Promise.all(
        ['general', 'errorMessages'].map(section => {
          return axios
            .get(`${I18N_CONFIG.translationsPath}/${localeId}/${section}.json`)
            .then(res => res.data);
        })
      )
        .then(sections => sections.map(flatten))
        .then(([general, errorMessages]) => {
          return {
            id: localeId,
            strings: {
              general,
              errorMessages
            },
            icon: localeImages(`./${localeId}.png`)
          };
        });
    }

    function getState() {
      return state;
    }

    /**
     * Changes current locale to specified one.
     * If specified locale isn't available, sets current locale to system default
     * If specified locale isn't yet downloaded, downloads it first
     * @param localeId
     * @param appState
     * @returns {*}
     */
    function setCurrentLocale(localeId, appState) {
      const dfd = $q.defer();

      const updateState = function(localeId) {
        i18next.changeLanguage(localeId);
        state.currentLocale = state.locales[localeId];
        cache = {};
        dfd.resolve(state.currentLocale);
        $rootScope.$broadcast('i18n:localeChanged');
        if (appState) {
          savePreferredLocale(appState, localeId);
        }
      };

      init().then(function(state) {
        let _localeId = localeId;
        if (!~state.availableLocalesIds.indexOf(localeId)) {
          _localeId = state.defaultLocale.id;
        }

        if (state.locales[_localeId]) {
          updateState(_localeId);
        } else {
          loadLocale(_localeId).then(updateState.bind(null, _localeId));
        }
      });
      return dfd.promise;
    }

    /**
     * Changes current locale to one specified for giver state
     * @param {string} appState - Application state (login, system)
     */
    function setPreferredLocaleFor(appState) {
      init().then(function(i18nState) {
        return setCurrentLocale(i18nState.preferredLocales[appState]);
      });
    }

    /**
     * Saves preferred locale for user
     * @param {string} appState - Application state (login, system)
     * @param localeId
     */
    function savePreferredLocale(appState, localeId) {
      state.preferredLocales[appState] = localeId;
      switch (appState) {
        case 'login':
          return sessionStorage.set(I18N_CONFIG.loginLocaleKey, localeId);
        case 'system':
          requestFactory.post('login', 'changeLocale', { localeId });
          return localStorage.set(I18N_CONFIG.systemLocaleKey, localeId);
        default:
          throw new Error(`Incorrect appState param provided: ${appState}`);
      }
    }

    /**
     * Flattens object
     * @param target
     * @param options
     * @returns {{}}
     */
    function flatten(target, options) {
      const opts = options || {};

      const delimiter = opts.delimiter || '.';
      let maxDepth = opts.maxDepth;
      let currentDepth = 1;
      const output = {};

      function step(object, prev) {
        Object.keys(object).forEach(function(key) {
          const value = object[key];
          const isarray = opts.safe && Array.isArray(value);
          const type = Object.prototype.toString.call(value);
          const isobject =
            type === '[object Object]' || type === '[object Array]';

          const newKey = prev ? prev + delimiter + key : key;

          if (!opts.maxDepth) {
            maxDepth = currentDepth + 1;
          }

          if (
            !isarray &&
            isobject &&
            Object.keys(value).length &&
            currentDepth < maxDepth
          ) {
            ++currentDepth;
            return step(value, newKey);
          }

          output[newKey] = value;
          return undefined;
        });
      }

      step(target);

      return output;
    }

    const interpolateString = function(string, params) {
      let i = 0;
      return string.replace(/\*\*/g, function() {
        return params ? params[i++] : '??';
      });
    };

    /**
     * Translates regular message in system
     */
    const translate = function translate(input, skipErrors, parameters) {
      if (!angular.isString(input)) {
        return input;
      }
      let params;
      switch (typeof skipErrors) {
        case 'undefined':
          break;
        case 'boolean':
          // eslint-disable-next-line prefer-rest-params
          params =
            typeof parameters === 'string'
              ? slice.call(arguments, 2)
              : parameters;
          break;
        case 'object':
          params = skipErrors;
          break;
        default:
          // eslint-disable-next-line prefer-rest-params
          params = slice.call(arguments, 1);
          break;
      }

      if (cache[input + params]) {
        return cache[input + params];
      }
      try {
        const state = getState();
        const localizedString =
          state.currentLocale.strings.general[input] ||
          state.locales[I18N_CONFIG.fallbackLocale].strings.general[input];

        if (angular.isString(localizedString)) {
          let resp = params
            ? interpolateString(localizedString, params)
            : localizedString;
          resp = I18N_CONFIG.helpers && highlightI18n ? `!${resp}!` : resp;
          cache[input + params] = resp;
          return resp;
        } else if (skipErrors) {
          return input.split('.').pop();
        }
        throw new Error(`There is no translation for ${input}`);
      } catch (ex) {
        return `?${input}?`;
      }
    };

    /**
     * Translates server messages
     *
     * @param key
     * @param params
     * @returns {*}
     */
    const translateResponse = function(key, params) {
      const dfd = $q.defer();
      let localizedString;
      init().then(function(state) {
        try {
          localizedString =
            state.currentLocale.strings[I18N_CONFIG.responsesSection][key] ||
            state.locales[I18N_CONFIG.fallbackLocale].strings[
              I18N_CONFIG.responsesSection
            ][key];
        } catch (e) {
          localizedString = 'Unknown error.';
        }
        let message = localizedString || key || 'Unknown error.';
        message = interpolateString(message, params);

        dfd.resolve(message);
      });
      return dfd.promise;
    };

    /**
     * Gets available system localizations
     *
     * @returns object with available and default localization
     */
    function getAvailableLocalizations() {
      return requestFactory
        .post(
          'system',
          'getAvailableSystemLocalizations',
          {},
          {},
          { skipErrors: [502, 504] }
        )
        .then(function(res) {
          return res.data && res.data.systemLocalizations
            ? res.data.systemLocalizations
            : [];
        })
        .then(function(availableLocales) {
          return availableLocales.map(function(locale) {
            return angular.extend({}, locale, {
              icon: localeImages(`./${locale.id}.png`)
            });
          });
        })
        .then(function(availableLocales) {
          const defaultLocale = window._.find(availableLocales, function(
            locale
          ) {
            return locale.isDefault;
          });
          return {
            availableLocales,
            defaultLocale
          };
        });
    }

    if (I18N_CONFIG.helpers) {
      window.$(window).keydown(function(e) {
        if (e.keyCode === 76 && e.ctrlKey === true && e.shiftKey === true) {
          $rootScope.$broadcast('i18n:localeChanged');
          $rootScope.$evalAsync(function() {
            cache = {};
            highlightI18n = !highlightI18n;
          });
        }
      });
    }

    injectNgDeps({ i18n: { setCurrentLocale } });

    return {
      init,
      translate,
      translateResponse,
      setCurrentLocale,
      setPreferredLocaleFor,
      updateLocalizationState,
      getState
    };
  });
